.card {
	position: relative;
	overflow: auto;
	max-width: 900px;
	min-height: 100px;
	margin: 0 auto 2em;
	background: white;
	box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}
.card-bar {
	overflow: auto;
	padding: 8px 0;
}
.card-bar:last-child {
	padding: 8px;
}
.card-bar-border {
	border-top: 1px solid #eee;
}
.card-img {
	float: left;
	width: 200px;
	min-height: 100px;
	height: 100%;
	background-position: center;
	background-size: cover;
}
.card-img-abs {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 200px;
	background-position: center;
	background-size: cover;
}
.card-content {
	margin-left: 200px;
}
.card-textual {
	padding: 0 24px 16px;
}
.card-title {
	display: block;
	padding: 8px 0;
	line-height: 32px;
	font-size: 21px;
	font-weight: 300;
	margin: 0;
	color: inherit;
}
@media (max-width: 920px) {
	.card-center {
		margin: 0 10px 2em;
	}
}

/* Material btn */

.btn {
	cursor: pointer;
	display: inline-block;
	padding: 0 16px;
	height: 36px;
	line-height: 36px;
	outline: none;
	border: none;
	border-radius: 2px;
	vertical-align: baseline;
	background: none;
	color: rgba(0, 0, 0, .6);
	font-family: Roboto, 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	box-shadow: none;
	user-select: none;
	&:hover {
		background: #ddd;
		color: black;
	}
}
.btn-icon {
	padding: 0;
	width: 36px;
}
.btn-primary {
	color: #27ae60;
	&:hover {
		color: #16a085;
		background: #f0f4f8;
	}
}
.btn.red {
	color: #DB2828;
	&:hover {
		color: #DB2828;
		background: #f0f4f8;
	}
}
.btn.green {
	color: #27ae60;
	&:hover {
		color: #27ae60;
		background: #f0f4f8;
	}
}
