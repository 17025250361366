@import "../../../node_modules/semantic-ui-css/components/reset";
@import "../../../node_modules/semantic-ui-css/components/site";

/* Layout */

@import "../../../node_modules/semantic-ui-css/components/grid";
@import "../../../node_modules/semantic-ui-css/components/container";
@import "./modules/structure";
@import "./modules/home";

/* Components */

@import "../../../node_modules/semantic-ui-css/components/table";
@import "../../../node_modules/semantic-ui-css/components/popup";
@import "../../../node_modules/semantic-ui-css/components/form";
@import "../../../node_modules/semantic-ui-css/components/label";
@import "../../../node_modules/semantic-ui-css/components/input";
@import "../../../node_modules/semantic-ui-css/components/checkbox";
// Fancy select
@import "../../../node_modules/semantic-ui-css/components/dropdown";
@import "../../../node_modules/semantic-ui-css/components/transition";
@import "../../../node_modules/semantic-ui-css/components/step";
@import "../../../node_modules/semantic-ui-css/components/message";
@import "../../../node_modules/semantic-ui-css/components/icon";
@import "../../../node_modules/semantic-ui-css/components/button";
@import "../../../node_modules/semantic-ui-css/components/menu";
@import "../../../node_modules/semantic-ui-css/components/item";
@import "./modules/material";
@import "./modules/fe";
@import "./modules/cms";
@import "./introjs.min";

/* FindsList */

.column img {
	max-width: 100%;
}
.list-right>.items {
	transition: opacity .2s;
}
.fetching .list-right>.items {
	opacity: 0.5;
	transition: opacity .6s;
}
.paging {
	overflow: auto;
	min-height: 2em;
}
.paging>.button {
	position: relative;
	z-index: 1;
}
.paging-current {
	line-height: 2.5em;
	@media (min-width: 450px) {
		position: absolute;
		width: 100%;
		left: 0;
		right: 0;
		text-align: center;
	}
}

/* Base */

dl {
	margin: 0 0 .5em;
}
dt {
	float: left;
	margin-right: 1rem;
	color: #999;
}
dd {
	margin-left: 90px;
}
.ui.create-container.form {
	margin: 0 auto;
	max-width: 800px;
}
.double-labeled>input:nth-child(2) {
	border-radius: 0!important;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-left-color: transparent;
	border-right-color: transparent;
}
.double-labeled>.label:nth-child(3) {
	border-radius: 0!important;
}
.double-labeled>input:last-child {
	border-top-left-radius: 0!important;
	border-bottom-left-radius: 0!important;
	border-left-color: transparent;
}

/* Map */

.map-controls {
	position: absolute;
	left: 10px;
	z-index: 123245;
	top: 60px;
	max-width: 20em;
}
.map-modal {
	background: white;
	padding: .5rem 1rem;
}
.vue-map-size {
	display: block;
	height: 300px;
	@media (min-height: 450px) {
		height: 350px;
	}
	@media (min-height: 500px) {
		height: 400px;
	}
}

/* Vue transitioning */

.fromleft-transition {
	transition: transform .5s ease;
	transform: translateX(0);
}
.fromleft-enter,
.fromleft-leave {
	transform: translateX(-100%);
}
.fromright-transition {
	transition: transform .5s ease;
	transform: translateX(0);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 60vh;
}
.fromright-enter,
.fromright-leave {
	transform: translateX(100%);
}

/* This effect is based on body class */

.fixed-top {
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	right: 0;
	.ui.menu {
		background: white;
	}
	nav {
		margin-bottom: 0;
	}
}
.nav-home {
	height: 0;
	.ui.green.menu .active.item {
		border-color: black !important;
		color: black !important;
	}
}
.listview {
	padding-top: 20px;
	height: calc(100% - 40px);
	overflow: auto;
}
.list-controls {
	overflow: auto;
}
.ui.basic.button:hover {
	background: none!important;
}
@media (min-width: 768px) {
	.list-left {
		float: left;
		width: 25%;
		padding-right: 16px;
		padding-bottom: 3rem;
	}
	.list-right {
		position: relative;
		float: right;
		width: 75%;
		padding-left: 16px;
	}
}
.mapview {
	margin-top: 1.5em;
	height: 50vh;
}

/* FindsList > Empty state */

h1,
h2 {
	small {
		display: block;
		opacity: .4;
	}
}
.status-lg,
.finds-empty {
	margin: 10% auto;
}
.finds-cta {
	margin: 5% auto;
}

/* FindsCreate > step */

.step.muted {
	h3 {
		color: #888;
		cursor: pointer;
		&:hover {
			opacity: 1;
		}
	}
	.field,
	.fields,
	h4,
	ul,
	p {
		display: none!important
	}
}
.step.active {
	margin-bottom: 3rem;
}
.step.required h3::after,
h4.required::after {
	content: '*';
	color: red;
	margin-left: 4px;
}
.step.completed {
	h3 {
		color: #090;
	}
	h3::after {
		color: #090;
	}
}

/* FindsCreate > feedback */

.li-feedback {
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

/* FindsList > FindsFilter */

.finds-order {
	float: left;
	padding-top: 8px;
	user-select: none;
	a {
		padding: 5px 8px;
		cursor: pointer;
		&:hover {
			background-color: #eee;
		}
	}
	.reverse,
	.active {
		font-weight: bold;
		color: #000;
	}
	.active::after {
		content: "\25b2";
		margin-left: 2px;
	}
	.reverse::after {
		content: "\25bc";
		margin-left: 2px;
	}
}
.wrapper-white {
	background: white;
}

/* Utils */

.text-right {
	text-align: right;
}
.pull-right {
	float: right;
}
.facet-a {
	display: block;
	padding: 3px 5px;
	line-height: 1em;
	color: #666;
	&:hover {
		text-decoration: underline;
	}
	&.active {
		color: black;
		font-weight: bold;
		background: #ddd;
		border-radius: 2px;
		&::before {
			float: right;
			content: 'x';
			color: red;
		}
	}
}
.facet-title {
	margin: 1rem 0 .5rem;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 300;
}
.facet-options {
	overflow: auto;
	max-height: 160px;
}

/* Admin - User panel*/

.tr-toggle {
	cursor: pointer;
	background: #f0f4f8;
	color: #999;
	&:hover {
		background: #bdc3c7;
	}
	&::after {
		content: '\2718';
	}
	&.on {
		background: #27ae60;
		color: white;
		&:hover {
			background: #2ecc71;
		}
		&::after {
			content: '\2713';
		}
	}
}

/* Notifications */

.ui.menu .ui.dropdown.item-notif .menu {
	width: 23em;
	max-height: 60vh;
	overflow: auto;
	.item {
		white-space: pre-wrap;
	}
	.item:hover {
		background: rgba(0, 0, 0, 0.05) !important;
		color: rgba(0, 0, 0, 0.95) !important;
		cursor: pointer;
	}
}
.ui.menu .item > .text > .circular.label {
	margin: -.35em .2em;
}
.read {
	background: #f5f5f5 !important;
	color: #999 !important;
	font-weight: 300;
}

/* Password strength component */

.pw-strength-fit>input {
	border-bottom-right-radius: 0!important;
	border-bottom-left-radius: 0!important;
}
.pw-strength {
	position: relative;
	margin: -1px 0 24px;
	background: #ddd;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.pw-strength::after {
	content: 'Kies een sterk wachtwoord';
	position: absolute;
	top: 6px;
	font-size: 12px;
}
.pw-strength-line {
	border-bottom: 6px solid #ddd;
	border-bottom-left-radius: 4px;
	width: 1%;
	transition: border-color .3s, width .3s;
}
.pw-strength1::after {
	content: 'Zwak';
	color: #c00;
}
.pw-strength1>.pw-strength-line {
	border-color: #f00;
	width: 25%;
}
.pw-strength2::after {
	content: 'OK';
	color: #c60;
}
.pw-strength2>.pw-strength-line {
	border-color: #f90;
	width: 50%;
}
.pw-strength3::after {
	content: 'Veilig';
	color: #00f;
}
.pw-strength3>.pw-strength-line {
	border-color: #66f;
	width: 75%;
}
.pw-strength4::after {
	content: 'Perfect';
	color: #090;
}
.pw-strength4>.pw-strength-line {
	border-color: #0c0;
	border-bottom-right-radius: 4px;
	width: 100%;
}
