/* FindEvent */

// .ui.items > .item > .image.fe-image {
// 	width: 400px;
// 	max-height: 250px;
// 	white-space: nowrap;
// 	overflow-x: auto;
// 	overflow-y: hidden;
// }
// .ui.items > .item > .image.fe-image > img,
// .fe-img {
// 	display: inline-block;
// 	margin-right: 1.5rem;
// 	width: auto;
// 	height: 250px;
// 	vertical-align: top;
// 	&:last-child {
// 		margin-right: 0;
// 	}
// }
// Divider
// @media only screen and (min-width: 767px) {
// 	.ui.items > .item > .image.fe-image {
// 		border-right: 2px solid rgba(34, 36, 38, 0.15);
// 	}
// }

/* FindEvent preview */

.fe-img-placeholder {
	background-color: #ccc;
	line-height: 250px;
	padding: 0 2rem;
	width: calc(100% - 1.5rem + 2px);
	text-align: center;
}

/* list of object features and findevent stuff*/

.fe-summary {}

/* simple image carousel */

.fe-header {}
.fe-header-fixed {}
.fe-card {
	padding: 16px 0 0;
	img {
		display: block;
		max-height: 30vh;
		max-width: 100%;
		width: auto;
	}
}
$magnify-color: black;
$magnify-size: 24px;
$magnify-margin: 0;
.fe-imglist {
	margin: -.5rem;
	.fe-img {
		padding: .5rem;
		position: relative;
		display: inline-block;
		max-width: 50%;
		vertical-align: top;
		cursor: zoom-in;
		// img {
		// 	position: relative;
		// 	transition: transform .2s;
		// }
		&:hover img {
			// z-index: 1;
			// transform: scale(1.1);
			box-shadow: 0 0 10px rgba(0, 0, 0, .2);
		}
		&:hover .magnify {
			opacity: 0;
		}
	}
	.magnify {
		transition: opacity .3s;
		position: absolute;
		z-index: 2;
		top: .5rem;
		right: .5rem;
		margin: $magnify-margin 0 0 $magnify-margin;
		border-radius: 2px;
		padding: 0 0 0 2px;
		width: $magnify-size;
		height: $magnify-size;
		line-height: $magnify-size;
		font-size: 16px;
		text-align: center;
		background: black;
		color: white;
		opacity: .3;
		pointer-events: none;
	}
	.fe-check {
		margin-right: .5rem;
	}
}
.scrolling.column {
	max-height: 70vh;
	overflow: auto;
}

/* Image remarks */

.fe-img-remark {
	display: block;
	line-height: 3rem;
	cursor: pointer;
}

/* Validation of finds */

dl {
	position: relative;
}
.fe-check {
	position: absolute;
	right: 100%;
	line-height: 24px;
	height: 24px;
	width: 24px;
	text-align: center;
	color: white;
	background: #F2711C;
	border-radius: 2px;
	margin-right: 1em;
	cursor: pointer;
	>.icon {
		margin: 0;
	}
	dl & {
		margin-top: -3px;
	}
}
.fe-valid {
	background: #21BA45;
}
.tooltip-show[data-tooltip]:before {
	-webkit-transform: rotate(45deg) scale(1)!important;
	-ms-transform: rotate(45deg) scale(1)!important;
	transform: rotate(45deg) scale(1)!important;
	opacity: 1;
}
.tooltip-show[data-tooltip]:after {
	transform: scale(1) !important;
	left: 0;
	bottom: 100%;
	margin-bottom: 0.5em;
}
.tooltip-show[data-tooltip]:before,
.tooltip-show[data-tooltip]:after {
	visibility: visible;
	pointer-events: auto;
}
[data-green][data-position~="top"][data-tooltip]:before {
	background: #21BA45;
	box-shadow: none;
}
[data-tooltip][data-green]:after {
	background: #21BA45;
	color: #FFFFFF;
	border: none;
	box-shadow: none;
}
.fe-validating {
	padding-left: 52px!important;
	.img {
		display: block;
	}
	.fe-img {
		margin-right: 5em;

	}
}
@media only screen and (max-width: 767px) {
	.ui.container > .ui.stackable.grid > .fe-validating {
		padding-left: 38px!important;
	}
}
