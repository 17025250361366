html {
	position: relative;
	min-height: 100%;
	height: auto!important;
}
body {
	padding-bottom: 240px;
	min-height: 100%;
	background: #f0f4f8;
}
nav {
	margin-bottom: 2rem;
}
footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	left: 0;
	right: 0;
	padding: 60px 20px;
	background: #2c3e50;
	color: white;
	a {
		color: inherit;
		&:hover,
		&:focus {
			color: transparentize(white, .4);
		}
	}
}
u {
	cursor: pointer;
}
h2 {
	font-size: 1.5rem;
	color: #666;
}
.mycontainer {
	margin: 0 auto;
	max-width: 50rem;
}
.ui.container-723 {
	max-width: 723px!important;
}
@media only screen and (min-width: 992px) {
	.mycontainer {
		max-width: 60rem;
	}
}
.row {
	margin: -1rem;
}
.col {
	padding: 1rem;
}
.ui.form .pct40.wide.field {
	width: 40% !important;
}
.ui.form .pct60.wide.field {
	width: 60% !important;
}
.loading,
[v-cloak] {
	display: none!important
}
.loading[v-cloak] {
	display: block!important
}
