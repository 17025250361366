.hero {
	display: flex;
	align-items: center;
	padding: 10% 0 20%;
	min-height: 100vh;
	background: url(../assets/img/bg_medea.jpg) no-repeat 75% 90%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	.lead {
		font-size: 1.6em;
		max-width: 33em;
	}
	@media only screen and (min-width: 1170px) {
		.hero {
			font-size: 16px;
		}
		.lead {
			font-size: 1.8em;
			max-width: 28em;
		}
	}
	.ui.button {
		margin-bottom: 1em;
	}
}
.ui.register-container.form {
	max-width: 500px;
	margin: 0 auto;
	padding: 10% 1rem;
	min-height: 100vh;
}
.field.expanding {
	opacity: 1;
	max-height: 40px;
	transition: max-height .5s, opacity .5s, margin .5s;
	transition-timing-function: linear;
}
.field.muted {
	opacity: 0;
	max-height: 0;
	margin: 0!important;
}